const API_END_POINT = "https://testing-api-backend.bwpapps.com/";
const REACT_APP_BEARER =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjMzODMzZjg5MzhlZWViYzdlMDMwNDY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjMzODc0YTY0Mzg2MDRlMzVkMDA3N2IyIn0.kSfvCsstJgheSX3BvsFqtUUIJ1Iybaq9MjCY-PHra3xmxrP3cM4ygGnw6NkKhBCFFSB6L8nJsmuCqGql02ABxzSOfPdAQD8gqGwJR7QWpBDEeyqGTnkUitAnpz83Ei_7cr1aMNX6gMgsZqFYvAev5Gm3KmRlQm5zfRklYL5wTsNvx6Bj9d1Yk9lGZYe5RxEbQ-CzdL-sH3HuO090BkQ3cweeQlRMOk_u851ZKB-fyvv2o3HN4CqaAz5PmSqHYPtPK1ux4cQ-dJ7PdsbI-ICXdHf9XoM917CTxlNIeU1Ie9YCjHfgN4f6BFtETRDhfBB8GDny2iv4UOFpjYXDh23h2Q";

export const environment = {
  production: true,
  isLive: false,
  API_ENDPOINT: API_END_POINT,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    default: {
      name: "Default",
      API_ENDPOINT: API_END_POINT,
      default: true,
      Bearer: REACT_APP_BEARER,
    },
  },
};
/* Bearer is App token for server */
